import { Routes, Route, HashRouter, Navigate, useLocation } from 'react-router-dom';

import { Login } from "./pages/Login";
import { PaymentModule } from './pages/PaymentModule';
import { useAuth } from './contexts/AuthContext';
import { Warning } from './pages/Warning';
import { Profile } from './pages/Profile';
import { DocumentTemplate } from './pages/DocumentTemplate';
import { PaymentDocs } from './pages/PaymentModule/PaymentDocs';
import { AdminUsers } from './pages/Admin/AdminUsers';
import { AdminProjects } from './pages/Admin/AdminProjects';
import { PageError } from './pages/Error';
import { Maintenance } from './pages/Maintenance';
import { storageKeys } from './services/cache';
import { AdminManagement } from './pages/Admin/AdminManagement';
import { Home } from './pages/Home';
import { PossiblePermissions } from './shared-types';
import { CoPilotDashboard } from './pages/CopilotDashboard';
import { Gallery } from './pages/Gallery';
import { ShowGallery } from './pages/Gallery/ShowGallery';
import Reconciliation from './pages/ReceivementModule/Reconciliation';
import POS from './pages/ReceivementModule/POS';
import { AdminIntegracoes } from './pages/Admin/AdminIntegracoes';
import { AdminCompany } from './pages/Admin/AdminCompany';
import { Reconciliated } from './pages/ReceivementModule/Reconciliated';
import { IconsPage } from './pages/Icons';
import { hubRoutes } from './shared-types/utils/routes';
import { Notification } from './pages/Notification'
import { PreferenceNotification } from './pages/Notification/PreferenceNotification';
import { CreateNotification } from './pages/Notification/CreateNotification';
import { RequireAuth } from './components/Routes/RequireAuth';
import { ShowDashboard } from './pages/CopilotDashboard/ShowDashboard';
import ShowCheckToken from './pages/Login/ShowCheckToken';
import { ClosingFolder } from './pages/ClosingFolder';
import { AdminCompanies } from './pages/Admin/AdminCompanies';
import { Session } from './pages/Session';

const route = hubRoutes;
const authRoutes = [
  { path: route.auth.login(), element: <RequireNotAuth><Login /></RequireNotAuth> },
  { path: route.auth.showCodeToken(':email'), element: <RequireNotAuth><ShowCheckToken /></RequireNotAuth> },
  { path: route.auth.logout(), element: <RequireAuth><Logout /></RequireAuth> },
];
const sessionRoutes = [
  { path: route.session.home(), element: (<Session />)
  },
]
const ivrimFlowsRoutes = [
  {
    path: route.old_cap.alert(), element: (
      <RequireAuth moduleName={PossiblePermissions.CONTAS_A_PAGAR}>
        <Warning />
      </RequireAuth>
    )
  }, {
    path: route.old_cap.home(), element: (
      <RequireAuth moduleName={PossiblePermissions.CONTAS_A_PAGAR}>
        <PaymentModule />
      </RequireAuth>
    )
  }, {
    path: route.old_cap.docs(':id'), element: (
      <RequireAuth moduleName={PossiblePermissions.CONTAS_A_PAGAR}>
        <PaymentDocs />
      </RequireAuth>
    )
  }, {
    path: route.old_cap.models(), element: (
      <RequireAuth moduleName={PossiblePermissions.CONTAS_A_PAGAR}>
        <DocumentTemplate />
      </RequireAuth>
    )
  },
];
const ivrimReconciliationRoutes = [
  {
    path: route.reconciliation.home(), element: (
      <RequireAuth moduleName={PossiblePermissions.FINANCEIRO}>
        <Reconciliation />
      </RequireAuth>
    )
  }, {
    path: route.reconciliation.manage(), element: (
      <RequireAuth moduleName={PossiblePermissions.FINANCEIRO}>
        <POS />
      </RequireAuth>
    )
  }, {
    path: route.reconciliation.history(), element: (
      <RequireAuth moduleName={PossiblePermissions.FINANCEIRO}>
        <Reconciliated />
      </RequireAuth>
    )
  },
];
const adminPanelRoutes = [
  {
    path: route.admin_panel.client(), element: (
      <RequireAuth isAdmin>
        <AdminCompany />
      </RequireAuth>
    )
  },
  {
    path: route.admin_panel.companies(), element: (
      <RequireAuth isAdmin>
        <AdminCompanies />
      </RequireAuth>
    )
  },
  {
    path: route.admin_panel.users(), element: (
      <RequireAuth isAdmin>
        <AdminUsers />
      </RequireAuth>
    )
  }, {
    path: route.admin_panel.projects(), element: (
      <RequireAuth isAdmin>
        <AdminProjects />
      </RequireAuth>
    )
  }, {
    path: route.admin_panel.dashboards(), element: (
      <RequireAuth isAdmin moduleName={PossiblePermissions.GESTAO}>
        <AdminManagement />
      </RequireAuth>
    )
  }, {
    path: route.admin_panel.integrations.whatsapp(), element: (
      <RequireAuth isAdmin>
        <AdminIntegracoes />
      </RequireAuth>
    )
  },
];
const coPilotDashboardRoutes = [
  {
    path: route.dashboard.home(), element: (
      <RequireAuth>
        <CoPilotDashboard />
      </RequireAuth>
    )
  },
  {
    path: route.dashboard.show(':slug'), element: (
      <RequireAuth>
        <ShowDashboard />
      </RequireAuth>
    )
  }
];
const galleryRoutes = [
  {
    path: route.gallery.home(), element: (
      <RequireAuth>
        <Gallery />
      </RequireAuth>
    )
  }, {
    path: route.gallery.show(':id'), element: (
      <RequireAuth>
        <ShowGallery />
      </RequireAuth>
    )
  },
]
const closingFolderRoutes = [
  {
    path: route.closing_folder.home(['*']), element: (
      <RequireAuth moduleName={[PossiblePermissions.FINANCIAL_CLOSINGS]}>
        <ClosingFolder />
      </RequireAuth>
    )
  }
]
const notificationRoutes = [
  {
    path: route.notification.all(), element: (
      <RequireAuth>
        <Notification />
      </RequireAuth>
    )
  }, {
    path: route.notification.preference(), element: (
      <RequireAuth>
        <PreferenceNotification />
      </RequireAuth>
    )
  }, {
    path: route.notification.create(), element: (
      <RequireAuth moduleName={[PossiblePermissions.ADMIN_HUB, PossiblePermissions.MANAGE_NOTIFICATION]}>
        <CreateNotification />
      </RequireAuth>
    )
  }
]
export const IS_HASH_ROUTER = true;
export const HandleRoutes = () => {
  return (
    <HashRouter>
      <Routes>
        {authRoutes.map(route => <Route {...route} key={route.path} />)}

        <Route index element={
          <RequireAuth >
            <Home />
          </RequireAuth>
        } />
        <Route path={route.profile.home()} element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        } />

        {[
          ...ivrimFlowsRoutes,
          ...adminPanelRoutes,
          ...coPilotDashboardRoutes,
          ...galleryRoutes,
          ...closingFolderRoutes,
          ...ivrimReconciliationRoutes,
          ...notificationRoutes,
          ...sessionRoutes,
        ].map(route => <Route {...route} key={route.path} />)}

        <Route path="/icones" element={
          <RequireAuth isAdmin>
            <IconsPage />
          </RequireAuth>
        } />
        <Route path="*" element={<PageError />} />
      </Routes>
    </HashRouter>
  )
}
function RequireNotAuth({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useAuth();
  let location = useLocation();

  let maintenance = IsMaintenance();
  if (maintenance) return maintenance;

  if (isAuthenticated()) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
export function IsMaintenance() {
  if (process.env.REACT_APP_UNDER_MAINTENANCE === 'true') {
    let isDev = localStorage.getItem(storageKeys.developers);
    if (!isDev) return <Maintenance />;
  }
  return null;
}
function Logout() {
  const { signOut } = useAuth();

  signOut();

  return <Navigate to="/" replace />;
}