import { ResultAndResponse } from "../shared-types";
import { Client } from "../types";
import { api, handleErrorResultAndResponse, headerBearer } from "./api";
import { clearCacheFromUserByEmail } from "./user";

export let cacheClient : Record<string, Client> = {}
const clearCacheFromClient = (token: string) => {
  if(cacheClient[token]) delete cacheClient[token]
}
export const getClient = async (token: string) : Promise<Client | undefined>=> {
  try{
    if(cacheClient[token]){
      console.log('[cached-client]')
      return cacheClient[token];
    }
  }catch(e){}

  try{
    const { data } = await api.get(`/client`, headerBearer(token));
    console.log('[requested-client]');

    if(data) cacheClient[token] = data

    return data
  }
  catch(e: any){
    const error = handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível obter os dados da empresa'
    })
    console.error(error);
    return undefined;
  }
}
export const removeUserOfClient = async (userId: string, token: string) : Promise<any> => {
  try{
    clearCacheFromClient(token)
    clearCacheFromUserByEmail(token)
  }catch(e){}

  try {
    const response = await api.delete(`/remove-user-from-client/${userId}`, headerBearer(token));
    return response.data;
  }catch(e){
    const error = handleErrorResultAndResponse(e, {
      result: false,
      response: "Não foi possível remove(r usuário da empresa"
    })

    console.error(error)
  }
}
interface UpdateClientResponse extends ResultAndResponse{
  data?: Client
}
export const updateClient = async (token: string, fields: Partial<Omit<Client, "id" | "users" | "sheet">>) : Promise<UpdateClientResponse> => {
  try {
    clearCacheFromClient(token)

    const { data } = await api.put(`/client`, fields, headerBearer(token));

    if(!data.result) throw new Error(data.response);

    return data
  } catch (error) {
    console.error(error)

    return handleErrorResultAndResponse(error, {
      result: false,
      response: "Não foi possível atualizar a empresa"
    })
  }
}
let cacheClients : Client[] = []
interface ResponseClients extends ResultAndResponse{
  data?: Client[]
}
export const getClients = async (token: string) : Promise<ResponseClients> => {
  try{
    if(cacheClients.length > 0){
      console.log('[cached-load-clients]')
      return {
        result: true,
        response: 'Empresas carregadas com sucesso',
        data: cacheClients
      }
    }
  }catch(e){}
  
  try{
    const { data } = await api.get('/clients', headerBearer(token))
    console.log('[requested-load-clients]')

    if(data.result && data.data) cacheClients = data.data
    
    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível buscar os usuários'
    })
  }
}
export const createClient = async (client: Omit<Client, 'id'>, token: string) => {
  try{
    const { data } = await api.post('/client', client, headerBearer(token))

    if(data.result && data.data) cacheClients.push(data.data)
    
    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível criar a empresa'
    })
  }
}