import { createContext, useContext, useEffect, useState } from "react";
import { authenticate, AuthenticateType, changeCurrentClient, ChangeCurrentClientResponseType } from "../services/authenticate";
import { storageKeys } from "../services/cache";
import { Client, ResultAndResponse, StorageKeyType, User } from "../types";
import { ResponseMeAuth, cachedUser, logout, me } from "../shared-components/services/authenticate";

interface UpdatableUserData{
  name: string,
  picture: string,
  whatsapp?: string
}
interface AuthContextType {
  user: User | undefined,
  signIn: (data: AuthenticateType) => Promise<void>,
  signOut: () => Promise<ResultAndResponse>,
  loadUser: () => Promise<User | undefined>,
  isAuthenticated: () => boolean,
  changeClient: (client_id: string, client_name: string, token: string) => Promise<ChangeCurrentClientResponseType>,
  refreshUser: (token: string) => Promise<ResponseMeAuth>,
  updateUser: (data: UpdatableUserData) => void,
  updateClient: (updatedClient: Client) => void,
  handleToken: () => string | undefined,
  storageKey: StorageKeyType,
  updateToken: (token: string) => void
}
export const AuthContext = createContext({} as AuthContextType);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const storageKey : StorageKeyType = storageKeys;

  const [user, setUser] = useState<User | undefined>();
  
  useEffect(() => {
    loadUser();
  }, []);

  // #region AUTH
  const signIn = async (data: AuthenticateType) => {
    let user = await authenticate(data);

    sessionStorage.setItem(storageKey.token, user.token);

    if(data.remember_me) localStorage.setItem(storageKey.token, user.token);
    setUser(user);
  };
  const signOut = async () : Promise<ResultAndResponse> => {
    let data = await logout();
    if(data.result){
      sessionStorage.removeItem(storageKey.token);
      localStorage.removeItem(storageKey.token);

      cachedUser.set(undefined);
      setUser(undefined);

      window.location.reload();
    }

    return data;
  };
  const isAuthenticated = () : boolean => {
    if(user && user.token) return true;

    return !!handleToken();
  }
  // #endregion AUTH
  const handleToken = () : string | undefined => {
    let tempToken = sessionStorage.getItem(storageKey.token);

    if(!tempToken){
      tempToken = localStorage.getItem(storageKey.token);

      if(tempToken) sessionStorage.setItem(
        storageKey.token, tempToken
      );
      else return undefined;
    }

    return tempToken;
  }
  const loadUser = async (updateState = true) : Promise<User | undefined> => {
    if(user) return user;

    let tempToken = handleToken()
    if(!tempToken) return undefined;

    const res = await me(tempToken)
    
    if(res.result){
      if(!res.data) return undefined;
      if(updateState) setUser(res.data);
  
      return res.data;
    }
  }
  const refreshUser = async (token: string) => {
    const res = await me(token);

    if(res.result && res.data){
      // setUser(res.data)
      updateStorage(res.data);
    }
    return res;
  }
  const updateUser = (data: UpdatableUserData) => {
    if(!user) return;

    const newUser : User = {
      ...user,
      name: data.name,
      picture: data.picture,
      whatsapp: data.whatsapp
    }

    setUser(newUser)
    updateStorage(newUser)
  }
  const updateStorage = (updatedUser: User) => {
    if(localStorage.getItem(storageKey.token)){
      localStorage.setItem(storageKey.token, updatedUser.token);
    }
    
    sessionStorage.setItem(storageKey.token, updatedUser.token);
  }
  const changeClient = async (client_id: string, client_name: string, token: string) : Promise<ChangeCurrentClientResponseType> => {
    if(!user) return {
      result: false,
      response: 'Faça o login novamente'
    };

    const response = await changeCurrentClient(client_id, token);
    if(response.result && response.data){
      setUser((old) => {
        if(!old) return;
        const { token, permitions, permitions_slug } = response.data!;

        let updatedUser = {
          ...old,
          client_name,
          token,
          permitions,
          permitions_slug,
          current_client: client_id
        };
        
        updateStorage(updatedUser);
  
        return updatedUser;
      });
  
      return {
        result: true,
        response: 'Empresa alterada',
        data: response.data
      };
    }
    return response;
  }
  const updateClient = (updatedClient: Client) => {
    if(!user || !user.clients) return;

    const newUser : User = {
      ...user,
      clients: user.clients.map((client) => client.id === updatedClient.id ? updatedClient : client)
    }

    setUser(newUser)
    updateStorage(newUser)
  }
  const updateToken = (token: string) => {
    if(localStorage.getItem(storageKey.token)){
      localStorage.setItem(storageKey.token, token);
    }
    sessionStorage.setItem(storageKey.token, token);
  }
  return (
    <AuthContext.Provider value={{
      user,

      signIn,
      signOut,
      loadUser,
      isAuthenticated,

      changeClient,

      updateUser,
      refreshUser,
      updateClient,
      handleToken,
      storageKey,
      updateToken
    }}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export const invalidTokenJWT = () => {
  sessionStorage.removeItem(storageKeys.token);
  localStorage.removeItem(storageKeys.token);

  cachedUser.set(undefined)

  window.location.reload();
}

export { storageKeys }